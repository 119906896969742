import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "A New Side Project",
  "tags": "Development iOS Side-Project",
  "date": "2014-07-20T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I’m going to try to write a real app this time. I have this idea for an app that I think would be pretty useful to me. I don’t want to reveal too much about it yet, but the design work is 75% done and I’m happy with it.`}</p>
    <p>{`It’s also going to be written completely in Swift. I know that a lot of people are advising against it. I don’t care. It’s a personal project, I have no deadlines, no existing codebase and no allegiance to Objective-C. I’ve played with Swift quite a bit recently and I really like it. It feels a lot more natural to write than Objective-C and I like the fact that I’m learning this language at the same time as thousands of people. I’m also convinced that Swift is the way forward, so why wait?`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <p>{`I’ll talk about it in more details as I get closer to Beta, but if you’re interested `}<a parentName="p" {...{
        "href": "http://twitter.com/vernalkick"
      }}>{`you should follow me on Twitter`}</a>{`. I’ll try to `}<a parentName="p" {...{
        "href": "/articles"
      }}>{`keep writing about my process`}</a>{` too, so keep an eye out for new posts soon.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`One thing that sucks for people writing Swift code is that the Xcode 6 beta is extremely buggy. I’m staying on beta 3 for now since it seems relatively stable for me, but definitely something to consider when thinking of starting a new project in Swift today.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      